<template>
  <modal
    name="remove-member-workspace"
    adaptive
    :max-width="500"
    width="100%"
    height="auto"
    styles="overflow: visible;"
    @closed="reset"
  >
    <div class="border-b p-4">นำออกจากเวิร์คสเปซ</div>
    <div class="m-4">
      <div class="mb-4">
        ชื่อเวิร์คสเปซ
      </div>
      <select-workspace
        :is-select-only="true"
        :selected="spaceSelected"
        class="mb-4"
        :workspace-list="workspaceList"
        @input="selectedSpace"
      />
      <div
        v-if="spaceSelected.length"
        class="flex border p-2 rounded flex-wrap mb-4"
      >
        <div
          v-for="(space, index) in spaceSelected"
          :key="index"
          class="flex mb-2 items-center mr-2 border py-1 px-4 rounded-full bg-gray-200"
        >
          <img
            class="w-5 h-5 object-cover rounded mr-2"
            :src="getInfoWorkspace(space)[0].imageLogo"
          />
          <span class="mr-2 truncate max-w-xs">
            {{ getInfoWorkspace(space)[0].name }}
          </span>
          <div @click="removeSpaceSelected(space)">
            <base-icon size="14">
              <icon-close />
            </base-icon>
          </div>
        </div>
      </div>
      <div class="flex text-center">
        <base-button
          class="ml-auto text-sm mb-4"
          color="transparent"
          @click="$modal.hide('remove-member-workspace')"
          >ยกเลิก</base-button
        >
        <base-button
          :disabled="spaceSelected.length == 0"
          class="text-sm mb-4"
          @click="onSumbit"
          >นำออก</base-button
        >
      </div>
    </div>
  </modal>
</template>

<script>
import Vue, { PropType } from 'vue';
import selectWorkspace from '@/components/select/select-workspace';
import { mapGetters } from 'vuex';
import IconClose from '@/components/icons/IconClose';
import apis, { teamsApi } from '@/services/apis';

export default Vue.extend({
  props: {
    workspaceList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    selectWorkspace,
    IconClose
  },
  data() {
    return {
      opened: false,
      spaceSelected: []
    };
  },
  computed: {
    ...mapGetters('teams', ['getTeams']),
    ...mapGetters('subscription', ['getMemberRemove'])
  },
  methods: {
    async onSumbit() {
      const removeMemberWorkspace = this.getMemberRemove.teamMembers.filter(
        item => this.spaceSelected.includes(item.team.id)
      );
      removeMemberWorkspace.forEach(element =>
        teamsApi
          .kickMember({
            id: element.teamID,
            memberID: element.id
          })
          .then(response => {
            this.$store.dispatch('subscription/fetchSeatMembers');
            this.$modal.hide('remove-member-workspace');
          })
      );
    },
    selectedSpace(e) {
      this.spaceSelected = e;
    },
    removeSpaceSelected(spaceId) {
      this.spaceSelected = this.spaceSelected.filter(function(a) {
        return a !== spaceId;
      });
    },
    getInfoWorkspace(spaceId) {
      return this.getTeams.filter(word => word.id === spaceId);
    },
    reset() {
      this.spaceSelected = [];
    }
  }
});
</script>

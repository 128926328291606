<template>
  <div class="flex">
    <div>
      <span>{{ $t('member') }}</span>
      <div class="flex items-center">
        <base-input-text
          v-model="search"
          inner-class="base-input--gray w-input"
          :placeholder="$t('search')"
          icon-fa="search"
          type="text"
          class="mr-8"
        />
        <select-workspace
          class="pr-4"
          :class="{ 'border-r-2': queryResult.length }"
          :selected="space"
          @input="selectedSpace"
        />
        <base-button
          v-if="search != '' || space.length != 0"
          wide="py-2 px-4"
          class="text-brand-blue bg-white border-brand-blue flex items-center ml-4"
          color="transparent"
          @click="clearFilter"
        >
          <span class="mr-2"> {{ $tc('result', queryResult.length) }}</span>
          <icon-x />
        </base-button>
      </div>
    </div>

    <div class="text-right ml-auto mt-auto">
      <base-button
        wide="py-2 px-4"
        icon="UserPlus"
        responsive
        class="text-brand-blue bg-white border-brand-blue"
        color="transparent"
        @click="openMemberInvite"
      >
        {{ $t('myBoard.invite') }}
      </base-button>
    </div>
  </div>
</template>
<script>
import selectWorkspace from '@/components/select/select-workspace';
import IconX from '@/components/icons/IconX';
export default {
  props: {
    queryResult: { type: [Array, Boolean] }
  },
  components: {
    selectWorkspace,
    IconX
  },
  data() {
    return {
      search: '',
      space: []
    };
  },
  methods: {
    openMemberInvite() {
      this.$modal.show('invite-plan');
    },
    selectedSpace(e) {
      this.space = e;
    },
    clearFilter() {
      this.search = '';
      this.space = [];
    }
  },
  watch: {
    search(val) {
      this.$emit('inputSearch', val);
    },
    space(val) {
      this.$emit('inputSpace', val);
    }
  }
};
</script>
<i18n>
{
  "en": {
    "member": "Member",
    "search": "Search by name or email",
    "result": "{n} result | {n} results"
  },
  "th": {
    "member": "สมาชิก",
    "search": "ค้นหาชื่อ อีเมล",
    "result": "{n} ผลการค้นหา"
  }
}
</i18n>
<style>
.w-input {
  width: 15rem !important;
}
</style>

<template>
  <div class="mt-8">
    <table class="w-full">
      <thead class="text-gray-caption">
        <tr class="uppercase">
          <th />
          <th class="text-left">{{ $t('name') }}</th>
          <th>{{ $t('status') }}</th>
          <th>{{ $t('ownership') }}</th>
          <th class="text-left">{{ $t('workspace') }}</th>
          <th>{{ $t('more') }}</th>
        </tr>
      </thead>
      <tbody class="border border-gray-border">
        <tr
          v-for="(member, index) in memberPerPage"
          :key="index"
          class="border-b border-gray-border py-2"
        >
          <td class="p-2 w-16">
            <img
              class="w-10 h-10 object-cover rounded-full"
              :src="
                (member.user && member.user.profilePicture) || defaultImageSrc
              "
            />
          </td>
          <td class="w-4/12">
            <div class="flex flex-col">
              <v-clamp autoresize :max-lines="1" class="max-w-xs text-sm">
                {{ (member.user && member.user.name) || '' }}
              </v-clamp>
              <v-clamp
                autoresize
                :max-lines="1"
                class="max-w-xs text-gray-caption text-sm"
              >
                {{ (member.user && member.user.email) || member.inviteToEmail }}
              </v-clamp>
            </div>
          </td>
          <td>
            <div
              v-if="member.userID == 0"
              class="status--awaiting-response  py-1 rounded-full text-xs mx-auto	"
            >
              {{ $t('subscription.members.pending') }}
            </div>
            <div
              v-else
              class="status--active py-1 rounded-full text-xs mx-auto"
            >
              {{ $t('team.members.active') }}
            </div>
          </td>
          <td class="text-center p-2">
            <span v-if="member.ownerID == member.userID">{{
              $t('owner')
            }}</span>
            <span v-else>{{ $t('member') }}</span>
          </td>
          <td class="mt-4 text-gray-500 text-sm flex">
            <div v-if="member.teamMembers.length > 0" class="flex">
              <v-popover
                v-for="(teams, index) in member.teamMembers"
                :key="index"
                offset="8"
                trigger="hover"
                class="cursor-pointer"
              >
                <img
                  v-if="index < workspceShow"
                  class="w-6 h-6 rounded mr-1 object-cover"
                  :src="teams.team.imageLogo"
                />
                <div slot="popover" class="text-sm mx-2">
                  <v-clamp
                    autoresize
                    :max-lines="1"
                    class="max-w-xs text-sm truncate"
                  >
                    {{ teams.team.name }}
                  </v-clamp>
                </div>
              </v-popover>

              <v-popover
                v-if="member.teamMembers.length > workspceShow"
                offset="8"
                trigger="hover"
                class="cursor-pointer"
              >
                {{
                  $tc('more_space', member.teamMembers.length - workspceShow)
                }}
                <div slot="popover" class="text-sm mx-2">
                  <p
                    v-for="(teamHide, index) in member.teamMembers"
                    :key="index"
                  >
                    <span v-if="index > workspceShow - 1">
                      <v-clamp
                        autoresize
                        :max-lines="1"
                        class="max-w-xs text-sm truncate"
                      >
                        {{ teamHide.team.name }}
                      </v-clamp>
                    </span>
                  </p>
                </div>
              </v-popover>
            </div>
          </td>
          <td class="text-center">
            <base-dropdown
              v-if="member.ownerID != member.userID"
              :max-width="250"
              position="bottom-right"
              class="cursor-pointer seat-dropdown__morpme"
            >
              <base-icon slot="toggle">
                <icon-dots-horizontal />
              </base-icon>
              <div
                v-for="{ label, classes, icon, modal } in confirmMenus"
                :key="label"
                class="text-left"
              >
                <base-dropdown-item
                  v-if="
                    member.teamMembers.length > 0 ||
                      modal != 'remove-member-workspace'
                  "
                  :class="classes"
                  @click="
                    toggleModal(modal, member.teamMembers);
                    setMemberRemove(member);
                  "
                >
                  <base-icon :width="18"> <component :is="icon"/></base-icon>
                  <span class="text-sm">{{ label }}</span>
                </base-dropdown-item>
              </div>
            </base-dropdown>
          </td>
        </tr>
      </tbody>
    </table>
    <base-pagination
      v-if="numOfMember > 10"
      :list="numOfMember"
      @pageCurrent="setPageCurrent"
      @perPage="setPerPage"
    />

    <modal
      name="remove-member"
      adaptive
      :max-width="500"
      width="100%"
      height="auto"
    >
      <modal-delete-member />
    </modal>

    <modal-remove-member-workspace :workspace-list="spaceUserSelected" />
  </div>
</template>
<script>
import VClamp from 'vue-clamp';
import IconDotsHorizontal from '@/components/icons/IconDotsHorizontal';
import IconListMenuLeave from '@/components/icons/IconListMenuLeave';
import IconPersonRemove from '@/components/icons/IconPersonRemove';
import { mapGetters } from 'vuex';
import modalDeleteMember from '@/components/modal/modal-delete-member';
import modalRemoveMemberWorkspace from '@/components/modal/modal-remove-member-workspace';
import { TeamMemberStatus } from '@/types/Team';

export default {
  props: {
    query: { type: Object, default: null }
  },
  components: {
    VClamp,
    IconDotsHorizontal,
    IconListMenuLeave,
    IconPersonRemove,
    modalDeleteMember,
    modalRemoveMemberWorkspace
  },
  data() {
    return {
      pageSelected: 1,
      member: [],
      dataPerPage: 10,
      workspceShow: 3,
      spaceUserSelected: []
    };
  },
  computed: {
    ...mapGetters('subscription', ['getSeatMember', 'getMemberRemove']),
    TeamMemberStatus() {
      return TeamMemberStatus;
    },
    confirmMenus() {
      const removeFromWorkspace = {
        label: this.$t('remove_member_workspace'),
        classes: '',
        icon: 'icon-list-menu-leave',
        modal: 'remove-member-workspace'
      };

      const removeMember = {
        label: this.$t('remove_member'),
        classes: 'text-red-danger',
        icon: 'icon-person-remove',
        modal: 'remove-member'
      };
      return [removeFromWorkspace, removeMember];
    },
    seatMember() {
      const self = this;
      const arrTeam = [];
      let member = this.getSeatMember;

      function filterSpace(member) {
        if (member.teamMembers.length > 0) {
          for (let i = 0; i < member?.teamMembers.length; i++) {
            for (let a = 0; a < self.query.space.length; a++) {
              if (member.teamMembers[i].team.id == self.query.space[a]) {
                if (arrTeam.indexOf(member) == -1) arrTeam.push(member);
              }
            }
          }
        }
        return arrTeam;
      }

      if (this.query.space.length > 0) {
        const withoutUser = this.getSeatMember.filter(m => m.user);
        withoutUser.filter(filterSpace);
        member = arrTeam;
      }

      const filterName = this.filterName(member);

      const queryResult =
        this.query.name != '' || this.query?.space.length > 0
          ? filterName
          : this.getSeatMember;
      this.query.name != '' || this.query?.space.length > 0
        ? this.$emit('queryResult', queryResult)
        : this.$emit('queryResult', false);

      return queryResult;
    },
    numOfMember() {
      const numAll = this.getSeatMember.length;
      const numFiltered = this.seatMember.length;
      return numFiltered != numAll ? numFiltered : numAll;
    },
    defaultImageSrc() {
      return `https://storage.googleapis.com/workkami-files-dev/statics/f6dd2895acbe4cf4b6d2efa5f279855f_thumb.png`;
    },
    memberPerPage() {
      const queryResultPerPage = this.seatMember.slice(
        (this.pageSelected - 1) * this.dataPerPage,
        this.pageSelected * this.dataPerPage
      );
      return queryResultPerPage;
    }
  },
  methods: {
    filterName(member) {
      const filter = member.filter(
        f =>
          (f.user &&
            f.user.name.toLowerCase().search(this.query.name.toLowerCase()) >=
              0) ||
          (f.user &&
            f.user.email.toLowerCase().search(this.query.name.toLowerCase()) >=
              0) ||
          f.inviteToEmail.toLowerCase().search(this.query.name.toLowerCase()) >=
            0
      );
      return filter;
    },
    setPageCurrent(e) {
      this.pageSelected = e;
    },
    setPerPage(e) {
      this.dataPerPage = e;
    },
    toggleModal(modal, member) {
      this.spaceUserSelected = member;
      this.$store.dispatch('teams/fetchTeamsNext');
      this.$modal.show(modal);
    },
    setMemberRemove(member) {
      this.$store.commit('subscription/SET_MEMBER_REMOVE', member);
    }
  },
  watch: {
    query: {
      handler(val) {
        this.pageSelected = 1;
      },
      deep: true
    }
  }
};
</script>

<style>
.seat-dropdown__more .base-dropdown__content {
  width: max-content;
}
</style>

<style scoped>
.status--active {
  @apply text-center px-2;
  max-width: fit-content;

  color: #88b864;
  background-color: #eaf5e3;
}
.status--awaiting-response {
  @apply text-center px-2;
  max-width: fit-content;

  color: #e86b1a;
  background-color: #fef0e3;
}

th {
  @apply font-normal text-xs;
}
</style>
<i18n>
{
  "en": {
    "name": "Name",
    "status": "Status",
    "ownership": "Ownership",
    "workspace": "Workspace",
    "more": "View more",
    "owner": "Owner",
    "member": "Member",
    "more_space": "and {n} others",
    "remove_member_workspace": "Remove from Workspace",
    "remove_member": "Remove Member"
  },
  "th": {
    "name": "ชื่อ",
    "status": "สถานะ",
    "ownership": "ตำแหน่ง",
    "workspace": "เวิร์คสเปซ",
    "more": "เพิ่มเติม",
    "owner": "เจ้าของ",
    "member": "สมาชิก",
    "more_space": "และอีก {n} เวิร์คสเปซ",
    "remove_member_workspace": "นำออกจากเวิร์คสเปซ",
    "remove_member": "ลบสมาชิก"
  }
}
</i18n>

<template>
  <div>
    <notifications
      group="change-seat"
      class="mt-2"
      :duration="5000"
      classes="vue-notification mr-2 text-base shadow"
    />
    <div class="border p-3 border-gray-border">
      <p class="text-sm">{{ $t('num_member') }}</p>
      <div>
        {{ $tc('person', getCurrentPlan.usedSeats) }} /
        {{ $tc('person', getCurrentPlan.totalSeats) }}
      </div>
      <bar-progress
        class="my-3"
        :value="getCurrentPlan.usedSeats"
        :maximum="getCurrentPlan.totalSeats"
        rounded
        :color="'#168AE2'"
      />
      <p class="text-sm	text-gray-caption">
        {{ $t('description') }}
      </p>
      <div class="flex border-t pt-2 text-sm  mt-2 border-gray-border">
        <div class="cursor-pointer flex items-center" @click="seatManage(true)">
          <base-icon>
            <icon-plus-circle />
          </base-icon>
          <span
            class="border-r pr-2 mr-2 border-gray-border"
            style="color:#2F80ED"
          >
            {{ $t('add_seat') }}</span
          >
        </div>

        <div
          class="cursor-pointer flex items-center"
          @click="seatManage(false)"
        >
          <base-icon>
            <icon-minus-circle />
          </base-icon>
          <span class="text-gray-caption"> {{ $t('remove_seat') }}</span>
        </div>
      </div>
    </div>

    <modal
      ref="edit-seat"
      name="edit-seat"
      :width="764"
      height="auto"
      class="overflow-visible"
    >
      <seatmanage-edit-seat :is-add="isAdd" @close="$modal.hide('edit-seat')" />
    </modal>
  </div>
</template>
<script>
import IconPlusCircle from '@/components/icons/IconPlusCircle';
import IconMinusCircle from '@/components/icons/IconMinusCircle';
import BarProgress from '@/components/bar-progress';
import seatmanageEditSeat from '@/components/seatmanage/seatmanageEditSeat';
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  components: {
    IconPlusCircle,
    IconMinusCircle,
    BarProgress,
    seatmanageEditSeat
  },
  data() {
    return {
      isAdd: Boolean
    };
  },
  methods: {
    seatManage(type) {
      this.isAdd = type;
      this.$modal.show('edit-seat');
    }
  },
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan'])
  }
});
</script>
<i18n>
{
  "en": {
    "num_member": "Members",
    "person": "{n} Member | {n} Members",
    "description": "Once the seats are full, you can add more seats by making extra purchase.",
    "add_seat": "Add Seats",
    "remove_seat": "Remove Seats"
  },
  "th": {
    "num_member": "จำนวนสมาชิก",
    "person": "{n} คน",
    "description": "สามารถเพิ่มที่นั่งได้หากที่นั่งเต็ม ซึ่งจะมีการเรียกเก็บที่นั่งเพิ่มเติม",
    "add_seat": "ซื้อที่นั่ง",
    "remove_seat": "ลดที่นั่ง"
  }
}
</i18n>


























import seatmanageMember from '@/components/seatmanage/seatmanageMember.vue';
import seatmanageFilter from '@/components/seatmanage/seatmanageFilter.vue';
import seatmanageTable from '@/components/seatmanage/seatmanageTable.vue';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    seatmanageMember,
    seatmanageFilter,
    seatmanageTable
  },
  data() {
    return {
      teamsId: [] as any,
      dataSearch: {
        name: '',
        space: []
      },
      query: []
    };
  },
  computed: {
    ...mapGetters('teams', ['getTeams'])
  },
  async mounted() {
    await this.$store.dispatch('subscription/fetchCurrentPlan');
    await this.$store.dispatch('teams/fetchTeams');
    this.getTeams?.forEach((team: any) => this.teamsId.push(team.id));
    this.$store.dispatch('subscription/fetchSeatMembers', this.teamsId);
  },
  methods: {
    search(e: string) {
      this.dataSearch.name = e;
    },
    space(e: any) {
      this.dataSearch.space = e;
    },
    queryFilter(e: any) {
      this.query = e;
    }
  }
});

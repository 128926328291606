<template>
  <div>
    <div class="title">
      <p v-if="isAdd">{{ $t('header.buy') }}</p>
      <p v-else>{{ $t('header.reduce') }}</p>
      <base-button
        data-testid="close-btn"
        class="absolute top-0 right-0 mt-6 mr-2"
        icon="close"
        icon-size="18"
        color="text"
        @click="closeModal"
      />
    </div>

    <div class="p-6">
      <div>
        <p>
          {{
            $t('seat_in_plan.title', {
              use: getCurrentPlan.usedSeats,
              total: getCurrentPlan.totalSeats
            })
          }}
        </p>

        <div class="flex flex-col text-sm mt-4">
          <div class="flex flex-row">
            <div class="flex flex-col w-full">
              <p class="mb-2">{{ $t('seat_in_plan.current_seat') }}</p>
              <div class="border p-2 rounded bg-gray-main">
                {{ getCurrentPlan.totalSeats }}
                {{ $t('subscription.payment.summary.seat') }}
              </div>
            </div>
            <div class="flex flex-col px-4 mt-8">
              <base-icon><icon-arrow-right /></base-icon>
            </div>
            <div class="flex flex-col w-full">
              <p class="mb-2">{{ $t('seat_in_plan.new_seat') }}</p>
              <base-select
                placeholder-classes="text-black"
                full-width
                max-height="130"
                :options="isAdd ? SelectOptionSeatAdd : SelectOptionSeatReduce"
                :placeholder="seatNum.label"
                @change="onDurationChanged"
              />
            </div>
          </div>

          <p class="text-gray-caption mt-2">
            {{
              $t('seat_in_plan.description', {
                add: seatNum.label,
                current: getCurrentPlan.totalSeats,
                new: seatNum.value - getCurrentPlan.totalSeats,
                plan: 4,
                price: 5
              })
            }}
          </p>
        </div>
      </div>
      <div v-if="isAdd" class="mt-6">
        <div v-if="getCardDefault">
          <p class="text-sm" style="margin-bottom: 5px">{{ $t('add_card') }}</p>
          <payment-card-default />
        </div>
        <div v-else class="py-12 border rounded text-center">
          <p class="mb-1">{{ $t('empty_card.title') }}</p>
          <p class="text-gray-caption text-sm mb-4">
            {{ $t('empty_card.description') }}
          </p>
          <base-button wide="px-6" @click="$modal.show('card-form')">
            {{ $t('subscription.payment.addCard') }}
          </base-button>
        </div>
      </div>

      <div class="flex mt-6">
        <base-button
          class="ml-auto"
          color="white"
          wide="px-6"
          @click="$modal.hide('edit-seat')"
        >
          {{ $t('cancel') }}
        </base-button>
        <base-button
          wide="px-6"
          :disabled="!getCardDefault && isAdd"
          @click="updateSeat"
        >
          {{ $t('proceed') }}
        </base-button>
      </div>
    </div>
    <modal
      ref="card-form"
      name="card-form"
      scrollable
      :width="400"
      height="auto"
    >
      <div class="border-b-2 padding-title-modal">
        {{ $t('subscription.payment.add_method') }}
      </div>
      <payment-card-form :is-add-card="true" />
    </modal>
  </div>
</template>
<script>
import IconArrowRight from '@/components/icons/IconArrowRight';
import IconChevronDown from '@/components/icons/IconChevronDown';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import paymentCardDefault from '@/components/payment/payment-card-default';
import paymentCardForm from '@/components/payment/payment-card-form';

export default Vue.extend({
  data() {
    return {
      seatNum: { label: '5 seat', value: 5 },
      numberNewSeat: 5,
      countSeat: 10,
      seat: 5
    };
  },
  components: {
    IconArrowRight,
    paymentCardDefault,
    paymentCardForm
    // IconChevronDownß
  },
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan', 'getCardDefault']),
    SelectOptionSeatAdd() {
      const SelectOptionSeat = [];
      for (let i = 0; i <= this.countSeat; i++) {
        if ((i + 1) * this.seat > this.getCurrentPlan.totalSeats) {
          SelectOptionSeat.push({
            label:
              i === this.countSeat
                ? 'Custom (Contect)'
                : (i + 1) * this.seat + this.$t('seat'),
            value: i === this.countSeat ? 0 : (i + 1) * this.seat
          });
        }
      }
      return SelectOptionSeat;
    },
    SelectOptionSeatReduce() {
      const SelectOptionSeat = [];
      for (let i = this.countSeat; i >= 0; i--) {
        if ((i + 1) * this.seat < this.getCurrentPlan.totalSeats) {
          SelectOptionSeat.push({
            label: (i + 1) * this.seat + this.$t('seat'),
            value: i === this.countSeat ? 0 : (i + 1) * this.seat
          });
        }
      }
      return SelectOptionSeat.reverse();
    }
  },
  async created() {
    await this.$store.dispatch('subscription/fetchCards');
  },
  mounted() {
    this.seatNum = this.isAdd
      ? this.SelectOptionSeatAdd[0]
      : this.SelectOptionSeatReduce[this.SelectOptionSeatReduce.length - 1];
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    async updateSeat() {
      const group = 'change-seat';

      this.$store.commit('subscription/SET_CHANGE_PLAN', [
        {
          changeSeat: true,
          isAdd: this.isAdd,
          numberOfSeats: parseInt(this.seatNum.value)
        }
      ]);
      await this.$store.dispatch('subscription/subscriptionPlanPreview', {
        numberOfSeats: parseInt(this.seatNum.value),
        planID: this.getCurrentPlan.payment.subscriptionOrder.planID
      });

      if (this.isAdd) {
        this.$modal.show('plan-seat');
        this.$store.dispatch('subscription/subscriptionSeatPreview', {
          numberOfSeats: parseInt(this.seatNum.value)
        });
      } else {
        try {
          await this.$store.dispatch('subscription/reduceTotalSeats', {
            numberOfSeats: parseInt(this.seatNum.value)
          });

          this.$notify({
            text: 'แก้ไขที่นั่งสำเร็จ',
            type: 'success',
            group
          });
        } catch (error) {
          this.$notify({
            text: error.response.data.message,
            type: 'error',
            group
          });
        }
      }

      this.closeModal();
    },
    onDurationChanged(value) {
      const option = this.isAdd
        ? this.SelectOptionSeatAdd
        : this.SelectOptionSeatReduce;
      const seatSelect = option.find(x => x.value === value);
      this.seatNum = seatSelect;
    }
  }
});
</script>

<style>
.title {
  @apply border-b px-6 py-4 text-lg border-gray-border;
  padding-top: 26px;
  padding-bottom: 14px;
  padding-left: 22px;
  padding-right: 22px;
}
</style>
<i18n>
{
  "en": {
    "header": {
      "buy": "Adding Seats",
      "reduce": "Removing Seats"
    },
    "seat_in_plan": {
      "title": "You are using {use} out of {total} available seats",
      "current_seat": "Current seat number",
      "new_seat": "New seat number",
      "description": "You have purchased an additional {add} to your original {current} seats, which add up to {new} seats in total.  You may cancel your purchasing plan at any time as your bill will be charged by next month. Please read your purchasing details carefully before making your purchase."
    },
    "empty_card": {
      "title": "Add a card to purchase your seats",
      "description": "Please add your debit/credit card to purchase your seats"
    },
    "add_card": "Payment method",
    "cancel": "Cancel",
    "proceed": "Proceed",
    "seat":" seat"
  },
  "th": {
    "header": {
      "buy": "ซื้อที่นั่ง",
      "reduce": "ลดที่นั่ง"
    },
    "seat_in_plan": {
      "title": "คุณกำลังใช้ {use} ที่นั่งในแผน {total} ที่นั่งของคุณ",
      "current_seat": "ที่นั่งปัจจุบัน",
      "new_seat": "ที่นั่งใหม่",
      "description": "คูณกำลังซื้อที่นั่งจากที่นั่งปัจจุบันมีอยู่ {add} เป็น {current} ที่นั่งโดยจะคิดที่นั่งเพิ่ม {new} ที่นั่ง  สามารถยกเลิกการชำระเงินได้ทุกเมื่อ โดยจะต้องชำระเงินก่อนใช้งานโปรดอ่านรายละเอียดต่างๆก่อน ชำระเงินค่าบริการ"
    },
    "empty_card": {
      "title": "เพิ่มบัตรเพื่อดำเนินการซื้อที่นั่ง",
      "description": "โปรดดำเนินการเพิ่มบัตรเดบิต/เครดิตก่อนการซื้อที่นั่งเพิ่ม"
    },
    "add_card": "วิธีการชำระเงิน",
    "cancel": "ยกเลิก",
    "proceed": "ดำเนินการต่อ",
    "seat":" ที่นั่ง"
  }
}
</i18n>

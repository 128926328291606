<template>
  <div>
    <div class="flex flex-col text-center m-6">
      <img
        :src="
          (getMemberRemove.user && getMemberRemove.user.profilePicture) ||
            defaultImageSrc
        "
        class="img--profile"
      />
      <img src="/img/remove-member.png" class="w-64 self-center mb-6" />
      <span class="text-xl mb-2">
        {{
          $t('title', {
            name:
              (getMemberRemove.user && getMemberRemove.user.name) ||
              getMemberRemove.inviteToEmail
          })
        }}
      </span>
      <p>
        {{
          $t('description', {
            name:
              (getMemberRemove.user && getMemberRemove.user.name) ||
              getMemberRemove.inviteToEmail
          })
        }}
      </p>
    </div>

    <div class="mt-2 text-center mb-4">
      <base-button color="danger" class="text-sm" @click="onSumbit">{{
        $t('btn_remove')
      }}</base-button>
      <div class="text-gray-caption mt-2 cursor-pointer" @click="close">
        {{ $t('btn_close') }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      opened: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters('subscription', ['getMemberRemove']),
    defaultImageSrc() {
      return `https://storage.googleapis.com/workkami-files-dev/statics/f6dd2895acbe4cf4b6d2efa5f279855f_thumb.png`;
    }
  },
  methods: {
    async onSumbit() {
      this.loading = true;
      this.$store.dispatch(
        'subscription/removeMember',
        this.getMemberRemove.id
      );
      this.loading = false;
      this.$modal.hide('remove-member');
    },
    close() {
      this.$modal.hide('remove-member');
    }
  }
});
</script>
<style scoped>
.img--profile {
  @apply w-16 h-16 object-cover rounded-full mx-auto absolute;
  top: 37px;
  left: 222px;
}
</style>

<i18n>
{
  "en": {
    "title": "Delete {name} now?",
    "description": "If you delete {name}, they won't be able to access any of their current Workspace. Do you wish to continue?",
    "btn_remove": "Delete Member",
    "btn_close": "Exit"
  },
  "th": {
    "title": "ต้องการลบ {name} ออกหรือไม่?",
    "description": "หากลบสมาชิกออก ทุกเวิร์คสเปซที่ {name} อยู่จะไม่สามารถเข้าถึงได้โปรดจงมันใจก่อนดำเนินการลบ",
    "btn_remove": "ลบสมาชิก",
    "btn_close": "ปิด"
  }
}
</i18n>
